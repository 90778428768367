import * as React from "react"
import Layout from "../components/layout";
import Seo from "../components/Seo/Seo";
import "../styles/terms.css"
import {Link} from "gatsby";
import {WhiteSubheading} from "../components/common/headings";

const Terms = () => (
    <Layout>
        <Seo
            title="Terms of use"
            description="Services that Mafiaverse provides, are subject to the Terms of Use."
            pageType="WebPage"
            ogType="website"
        />
        <div className="content">
            <div className="terms-of-use-container has-text-white py-6">
                <div className="container px-2">
                    <h1 className="title is-underlined has-text-centered mafia-heading">TERMS OF USE</h1>
                    <p/>
                    <WhiteSubheading>Definitions</WhiteSubheading>
                    <ul>
                        <li>"Mafiaverse Collections" refers to all original collections of randomly generated images, 2D
                            or
                            3D avatars and other assets created by Mafiaverse.
                        </li>
                        <li>"The Monks Syndicate" / “Monks” / “The Syndicate” / “TMS” refers to the original collection
                            of
                            10,000 randomly generated 2D and 3D avatars created by Mafiaverse.
                        </li>
                        <li>"The Monks Syndicate NFT" are defined as any individual art, design, 2D drawings or models
                            that
                            may be associated with a NFT from the Mafiaverse Collections.
                        </li>
                        <li>"Traits", "Properties" and “Attributes” are defined as the distinguishing characteristics
                            and
                            proprietary individually layered art files associated with each NFT. These include but are
                            not
                            limited to Colours, Backgrounds, Bodies, Heads, Accessories as well as any non visual
                            attributes
                            for NFTs in the "Mafiaverse Collections".

                        </li>
                    </ul>
                    <WhiteSubheading>Intellectual Property
                        Rights</WhiteSubheading>
                    <p>The name and any related software, art, assets, code and any other Intellectual Property related
                        to
                        "Mafiaverse" are owned by ”Mafiaverse”. All rights that are not specifically granted to the
                        users
                        and owners of particular NFTs in the Mafiaverse Collections below are reserved by “Mafiaverse”
                        or
                        potential future DAO. Any artefact that is developed, altered, made or conceived in whole or in
                        part
                        by “Mafiaverse”(including any developed jointly with you) as a result of our relationship with
                        you
                        shall become and remain the sole and exclusive property of “Mafiaverse”. You agree to make no
                        claim
                        in the rights or ownership of any such form, software, art, assets or artefacts.</p>
                    <WhiteSubheading>What you Own</WhiteSubheading>
                    <p>
                        Holding a "Monk" NFT in your Ethereum wallet grants you personal use and commercial rights as
                        stated
                        in this document.
                        Ownership of NFTs is verified cryptographically on the Ethereum blockchain.
                        All personal use and commercial rights are revoked once you transfer or sell the NFT to another
                        wallet.
                    </p>
                    <WhiteSubheading>Personal Use</WhiteSubheading>
                    <p>Subject to compliance with these terms stated here in this document, Mafiaverse grants you a
                        world-wide royalty-free licence to use, copy and display The Monks NFTs that you own.</p>
                    <p>This usage is for the limited purposes of</p>
                    <ul>
                        <li>Display and usage of your NFT on External Websites</li>
                        <li>Ability to Buy, Sell, and Trade your NFT</li>
                    </ul>
                    <WhiteSubheading>Commercial and Derivative Rights</WhiteSubheading>
                    <p>You are free to use your Monks NFTs for commercial purposes as long as you comply with the terms
                        stated in this document.</p>
                    <ul>
                        <li>You must use the Monks NFT in its entirety. You are not granted any rights to individual art
                            or
                            non art layers or attributes.
                        </li>
                        <li>
                            "Mafiaverse'' expressly prohibits the use of any proprietary art, individual traits or
                            layers in
                            any and all other projects.
                        </li>
                        <li>
                            "Mafiaverse" also prohibits the sale or promotion of any counterfeit, knock-off, replica,
                            imitation, clone or ‘similar terms’ NFTs or NFT collections in an attempt to pass themselves
                            as
                            genuine "Mafiaverse Creations".
                        </li>
                        <li>
                            Use of any licensed materials from Mafiaverse is prohibited in any way which is unlawful,
                            fraudulent, libellous, defamatory, obscene, pornographic, profane, threatening, abusive,
                            hateful, offensive or otherwise objectionable or unreasonable or infringing upon any laws or
                            regulations or intellectual property rights or proprietary rights or confidentiality
                            obligations
                            and you shall indemnify and defend Mafiaverse against any claims, damages, proceedings, loss
                            or
                            costs arising from such use. Users shall not use Licensed Materials from Mafiaverse in any
                            way
                            that could be construed as being adverse or derogatory to the image of Mafiaverse or any of
                            its
                            subjects.
                        </li>
                    </ul>
                    <p>You are otherwise free to use your Monks in the fullest sense of commercial and derivative use
                        cases.
                        For example:- “Monk#1234” Merchandise like T-Shirts, Clothing, etc.</p>
                    <WhiteSubheading>Mafiaverse NFTs are NOT investment
                        vehicles</WhiteSubheading>
                    <p>Mafiaverse NFTs are collectible digital art pieces that have some utility within our platform.
                        They
                        were created as art pieces intended for people to enjoy, not as a financial instrument.
                        Mafiaverse
                        makes absolutely no promises or guarantees regarding the value of Mafiaverse NFTs.</p>
                    <WhiteSubheading>Taxes</WhiteSubheading>
                    <p>You are entirely and solely responsible for any tax liability which may arise from minting,
                        selling,
                        or owning NFTs or Tokens from Mafiaverse Collections.</p>
                    <WhiteSubheading>Children</WhiteSubheading>
                    <p>The Mafiaverse project is not targeted towards children. You agree that you are over the age of
                        18,
                        or above the legal age of your jurisdiction, whichever is greater.</p>
                    <WhiteSubheading>Disclaimers</WhiteSubheading>
                    <ul>
                        <li>"Mafiaverse" and "The Monks Syndicate" are only a collection of "collectible" artworks
                            and
                            other
                            NFTs.
                        </li>
                        <li>All the materials produced and published as part of "Mafiaverse Collections" are
                            fictional
                            and is not related to or represent any person, group or religion in real life in any way
                            whatsoever.
                        </li>
                        <li>
                            "Mafiaverse" does not engage in, promote, encourage or support any criminal, unlawful,
                            fraudulent or any activity infringing upon any laws or regulations in any way whatsoever
                            in
                            real life.
                        </li>
                        <li>
                            "Mafiaverse" does not promote, encourage or support use of drugs in any form, alcohol or
                            any
                            substance which is considered illegal by law and regulations in real life.
                        </li>
                    </ul>
                    <WhiteSubheading>These Terms are Subject to Change</WhiteSubheading>
                    <p>We may revise these Terms from time to time, but the most current version will always be on&nbsp;
                        <Link to="https://mafiaverse.io/terms">mafiaverse.io/terms</Link>. You are responsible for
                        reviewing
                        and becoming familiar with any such
                        modifications. Revisions deemed material, in the sole discretion of Mafiaverse, will include a
                        notification via Twitter or Discord or Email. By continuing to access The Mafiaverse website and
                        by
                        holding one or more NFTs from The Mafiaverse Collections, you agree to be bound by the current
                        Terms.</p>
                </div>
            </div>
        </div>
    </Layout>
)

export default Terms;